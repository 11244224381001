<template>
  <div>
    <div class="row">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th class="text-center">Datum</th>
              <th class="text-center">Vytvořil</th>
              <th class="text-center">Skladové číslo</th>
              <th class="text-center">Název položky</th>
            </tr>
            </thead>
            <tbody class="table-bordered">
            <template v-for="nahradniDil in nahradniDily">
              <tr>
                <td class="text-center" v-html="nahradniDil.datum_vytvoreni_format">

                </td>
                <td class="text-center">
                  {{ nahradniDil.vytvoril_prijmeni }} {{ nahradniDil.vytvoril_jmeno }}
                </td>
                <td class="text-center">
                  {{ nahradniDil.skladove_cislo }}
                </td>
                <td class="text-left">
                  {{ nahradniDil.nazev_polozky }}
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</template>

<script>

import axiosApiPost from "../axiosApiMethod";
import {bus} from "../main";

export default {
  name: "ZalozeneND",
  data () {
    return {
      nahradniDily : [],
    }
  },
  created() {

    let params = {
      vcetne_pol_nd_zmen : 1
    };
    axiosApiPost('get-nahradni-dily', params, 'data').then( result => {
        this.nahradniDily = result;
    });
  },
}
</script>

<style scoped>

</style>